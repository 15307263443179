import React, { useCallback, useEffect } from 'react';
import { injectIntl, defineMessages } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { N20, N900 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { token } from '@atlaskit/tokens';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { sharedi18n } from '../sharedi18n';
import { ContentStateColor, ContentStateRestrictionLevel } from '../ContentStateColor';
import { colorCodeToColorName } from '../helpers';
import { determineSecondaryColor } from '../EditorContentState/helpers';

import { type RendererContentStateComponentProps } from './types';

const i18n = defineMessages({
	lastUpdated: {
		id: 'content-state.last-updated',
		description:
			'Message in tooltip when user hovers over content state that tells them when the status was last updated',
		defaultMessage: 'Last updated',
	},
	justAMomentAgo: {
		id: 'content-state.just-a-moment',
		description:
			'Default message in tooltip when user hovers over content state that tells them when the status was last updated',
		defaultMessage: 'just a moment ago',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RendererContentStateWrapper = styled.li({
	display: 'flex',
	alignItems: 'center',
	cursor: 'default',
	minHeight: '32px',
	backgroundColor: token('color.background.neutral', N20),
	borderRadius: '3px',
	paddingRight: token('space.100', '8px'),
	whiteSpace: 'nowrap',
	marginLeft: token('space.075', '6px'),
	marginRight: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentStateNameWrapper = styled.span({
	color: token('color.text', N900),
	fontWeight: 500,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlexWrapper = styled.div({
	display: 'flex',
});

const RendererContentStateComponentImpl = ({
	contentId,
	contentState,
	lastUpdatedInfo,
	isPagePreview,
	intl: { formatMessage },
}: RendererContentStateComponentProps) => {
	const updatedInfo = lastUpdatedInfo || formatMessage(i18n.justAMomentAgo);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isPageOwnerRestricted =
		contentState?.restrictionLevel ===
		ContentStateRestrictionLevel[ContentStateRestrictionLevel.PAGE_OWNER];
	const tooltipContent = isPageOwnerRestricted
		? formatMessage(sharedi18n.verifiedRestricted)
		: `${formatMessage(i18n.lastUpdated)}: ${updatedInfo}`;

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'rendererContentState',
				attributes: {
					color: colorCodeToColorName(contentState?.color?.toUpperCase()),
					restrictionLevel: contentState?.restrictionLevel?.toUpperCase(),
				},
			},
		}).fire();
	}, [createAnalyticsEvent, contentState, contentId]);

	const handleMouseEnter = useCallback(() => {
		if (!isPagePreview) {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'hovered',
					actionSubject: 'button',
					objectId: contentId,
					actionSubjectId: 'rendererContentStateButtonHovered',
					source: 'rendererContentStateButton',
					attributes: {
						contentId,
					},
				},
			}).fire();
		}
	}, [contentId, isPagePreview, createAnalyticsEvent]);

	const isContentStateVisualExperimentEnabled = FeatureGates.getExperimentValue(
		'content_state_visual_appearance_experiment',
		'isEnabled',
		false,
	);

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
	const contentStatePrimaryColor = contentState?.color;

	return (
		<FlexWrapper>
			<Tooltip content={tooltipContent} position="bottom">
				<RendererContentStateWrapper
					onMouseEnter={handleMouseEnter}
					data-testid="renderer-content-state-wrapper"
					style={{
						backgroundColor: isContentStateVisualExperimentEnabled
							? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
								determineSecondaryColor(contentStatePrimaryColor)
							: token('color.background.neutral', N20),
					}}
				>
					<ContentStateColor
						appearance="rendererContentState"
						dataTestId="renderer-content-state-color"
						color={contentState?.color}
						restrictionLevel={contentState?.restrictionLevel}
					/>
					<ContentStateNameWrapper>{contentState?.name}</ContentStateNameWrapper>
				</RendererContentStateWrapper>
			</Tooltip>
		</FlexWrapper>
	);
};

export const RendererContentStateComponent = injectIntl(RendererContentStateComponentImpl);
